@import "styles/_variables.scss";

.title{
    text-align: center;
    margin-bottom: 20px;
}

.subtitle{
    text-align: center;
    margin-bottom: 40px;
}

.cards{
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    &__item{
        display: block;
        width: 384px;
        @media(max-width: $tabletPoint) {
            width: 352px;
        }

        @media(max-width: $mobilePoint) {
            width: 100%;
        }
    }
}