@import "styles/_variables.scss";

.card {
  border: 1px solid $bloghrColor;
  border-radius: 4px;
  color: $mainText;
  font-family: $fontFamily;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s all;
  
  &:hover {
    border-color: $footerBackground;

    & .card__svg {
      background: $footerBackground;

      & svg path {
        transition: 0.3s all;
        fill: white;
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }

  &__svg {
    border: 1px solid $footerBackground;
    border-radius: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s all;

    & div {
      height: 24px;
    }
  }
}
